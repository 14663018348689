// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { Component } from 'react';
import { Link } from 'gatsby'
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import { Form, ToggleButtonGroup, ToggleButton, InputGroup } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import AddressSection from "./AddressSection"
import Select from 'react-select'
//import './cardDetailed.css';

import "./CheckoutForm.css"
import api from "../../api"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const options = [
  {value: 'I love water and to swim! ', label: 'I love water and to swim! '},
  {value:'My kids love water and to swim!', label: 'My kids love water and to swim!' },
  {value:'I support water safety and education', label:'I support water safety and education'},
  {value:'I used a lifejacket at a loaner station', label:'I used a lifejacket at a loaner station'},
  {value:'I want to see an aquatic facility in the valley', label: 'I want to see an aquatic facility in the valley'},
  {value:'Referred by a friend', label:'Referred by a friend'},
  {value:'Social Media', label:'Social Media'},
  {value:'Sign/Advertising', label: 'Sign/Advertising'},
  {value:'Other', label: 'Other'},
]

class StripeCheckoutForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      payment_method_id: "",
      amount: 100,
      currency: "usd",
      clientSecret: null,
      error: null,
      disabled: false,
      succeeded: false,
      processing: false,
      receipt_email: null,
      selectAmount: null,
      metadata: {
        address: '',
        name: '',
        city: '',
        state: '',
        zip: '',
        selectedOption: null,
      },
  
    }
   // this.handleData = this.handleData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  setAmount(event) {
    console.log(event.target.value);
    // Conver the string to an integer to pass to API
    const donationAmount = parseInt(event.target.value)
    console.log(donationAmount, typeof(donationAmount));
    this.setState({
      amount: donationAmount,
    })
  } 

    // Pass data from AddressSection component to parent
    updateData = (target, value) => {
      this.setState({
        [target]: value
      });
    }

    handleChange = selectedOption => {
      this.setState({ selectedOption });
      console.log(selectedOption);
    }



  async handleSubmit(ev) {
    ev.preventDefault()
    const {stripe, elements} = this.props;

    api
      .createPaymentIntent({
        payment_method_types: ["card"],
        amount: this.state.amount,
        receipt_email: this.state.receipt_email,
        metadata: {
          name: this.state.name,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          email: this.state.receipt_email,
          selectedOption: this.state.selectedOption.value,
        }
        //customer: this.state.customer,
      })
      .then(clientSecret => {
        this.setState({
          client_secret: clientSecret,
          disabled: true,
          processing: true,
        })
        console.log(clientSecret)

        // confirmCardPayment seems to be required - not handleCardPayment
        this.props.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: this.state.email,
              name: this.state.name,
            },
          }
        }).then(payload => {
              if (payload.error) {
                this.setState({
                  error: `Payment failed: ${payload.error.message}`,
                  disabled: false,
                  processing: false,
                })
                console.log("[error]", payload.error)
              } else {
                this.setState({
                  processing: false,
                  succeeded: true,
                  error: "",
                  metadata: payload.paymentIntent,
                })
                console.log("[PaymentIntent]", payload.paymentIntent)
              }
            })
      })
      .catch(err => {
        this.setState({ error: err.message })
      })
  }

  renderSuccess() {
    return (
      <div className="sr-field-success message">
        <h2>Thank you for your donation!</h2>
        <p>Please check your email for a donation receipt, and please don't forget to <Link to="">sign up</Link> for our newsletter!</p>

        {/* <p>View PaymentIntent response:</p>
        <pre className="sr-callout">
          <code>{JSON.stringify(this.state.metadata, null, 2)}</code>
        </pre> */}
      </div>
    )
  }



  renderForm() {
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    }

    const { selectedOption } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="sr-combo-inputs">
          {/* <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              placeholder="Name"
              autoComplete="cardholder"
              className="sr-input"
            />
          </div> */}

          <AddressSection amount={this.state.amount} updateData={this.updateData}/>
          <div className="row mb-4" >
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Why did you donate?</label>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                    </div>
                </div>
                <div className="col-sm-4 mb-2" >
                <div onChange={event => this.setAmount(event)}>
                  <label style={{marginRight: '10px'}}>Donation Amount</label>
                  {/* <ToggleButtonGroup type="radio" name="donation" defaultValue={[100]}> */}
                  <ToggleButtonGroup type="radio" name="donation" defaultValue={[100]}>
                    <ToggleButton value={25} name="donationAmount" className="btn-action">$25</ToggleButton>
                    <ToggleButton value={50} name="donationAmount" className="btn-action">$50</ToggleButton>
                    <ToggleButton value={100} name="donationAmount" className="btn-action">$100</ToggleButton>
                    <ToggleButton value={250} name="donationAmount" className="btn-action">$250</ToggleButton>
                  </ToggleButtonGroup>
                  </div>
                  </div>
                  <div className="col-sm-4 mb-2" onChange={event => this.setAmount(event)} >
                    <Form.Group>
                  <Form.Label>Other Amount</Form.Label>
                  <InputGroup>
                  <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
            </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="donationAmount" placeholder="Whole dollar amount please!"/>
                    </InputGroup>
                  </Form.Group>
                </div>
                
              </div>

          <div className="sr-combo-inputs-row">
            <label>Payment Information</label>
            <CardElement
            options={CARD_ELEMENT_OPTIONS} 
            className="sr-input sr-card-element MyCardElement"
          
            />
          </div>
        </div>

        {this.state.error && (
          <div className="message sr-field-error">{this.state.error}</div>
        )}

        {!this.state.succeeded && (
          <button
            className="donateButton btn"
            data-secret="{{ACTUAL_SECRET}}"
            disabled={this.state.disabled}
          >
            { this.state.processing
              ? "Processing…"
              : `Donate $${this.state.amount}`}
          </button>
        )}
      </Form>
    )
  }

  render() {
    return (
      <div className="checkout-form">
        <div className="sr-payment-form">
          <div className="sr-form-row" />
          {this.state.succeeded && this.renderSuccess()}
          {!this.state.succeeded && this.renderForm()}
        </div>
      </div>
    )
  }
}

  const InjectedCheckoutForm = () => {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <StripeCheckoutForm elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    );
  };

  export default InjectedCheckoutForm
  
