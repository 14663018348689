import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import InjectedCheckoutForm from "./StripeCheckoutForm";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.

//const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));
const stripePromise = loadStripe('pk_live_51H87xGLfQGK8iVuekRDL0fPuvkE8NzsyLpK4TpEcXnRqkij20E8f9jQMAVDH4qVc84JpyKTd7xAF5ypqFhQHXJRH00OlgJzBTq')
//const stripePromise = loadStripe('pk_test_51H87xGLfQGK8iVuectVzhK8YbSAq9SfwEq8Q3nNwCbrq3rrSJyRZzcXzitjbPULf3Tp0hTxq0J4vOJ2niER0ITDp00UKRayyk2')

const DonationWrapper = () => {
    return (
        <div>
            <Elements stripe={stripePromise}>
                <InjectedCheckoutForm />
            </Elements>
        </div>
    )
}

export default DonationWrapper