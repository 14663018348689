import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

//import CheckoutForm from "../components/DonationForm/CheckoutForm"
import DonationWrapper from "../components/DonationForm/DonationWrapper"

const DonatePage = () => (
    <Layout>
        <SEO title="Donate to Teton Valley Aquatics" />
        <Container>
            <Row style={{
                marginTop: '6rem'
            }}>
                <Col>
                <h1 style={{
                    color: '#003366'
                }}>Donate now to make our vision a reality!</h1>
                <p>Teton Valley Aquatics is a registered 501(c)3 and all donations are tax deductible.</p>
                <p>Larger donations should be made via check. Please <Link className="blueLink" to="/connect">contact us</Link> to arrange. Checks can be made out to Teton Valley Aquatics, Ltd and mailed to PO Box 583, Driggs, ID 83422.</p>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="my-4" style={{
                paddingBottom: '6rem'
            }}>
                <Col>
                <DonationWrapper />        
                </Col>
            </Row>
        </Container>
        
    </Layout>
)

export default DonatePage